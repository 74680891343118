.carousel-container {
  max-width: 100%;
  margin: auto;
  padding: 50px 0;
  background: #1f2a50;
  margin-bottom: 40px;

  @media (max-width: 480px) {
    padding: 30px 0;
  }
}

.carousel-slide {
  padding: 0px;
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
  display: flex;
  justify-content: center;
  position: relative;
}

.slick-center {
  transform: scale(1.1);
}


.slick-dots {
  bottom: -25px;

  li button:before {
    color: white;
    opacity: 0.5;
    font-size: 10px;
  }

  li.slick-active button:before {
    opacity: 1;
    color: white;
  }
}

.slick-next {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid black;
  z-index: 2;
  background: white;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  color: black;
  display: flex;
  justify-items: center;
  align-items: center;
}

.carousel-slide:not(.slick-center) {
  transform: scale(0.8);
}

.carousel-slide:not(.slick-center)::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}

.slick-center .carousel-slide::after {
  display: none;
}

.carousel-slide img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  background: white;
  color: black;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
}

.slick-prev {
  left: 20px;
}

.slick-next {
  right: 20px;
}

.prev-arrow {
  position: absolute;
  left: 10px;
  top: 45%;
  z-index: 1;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-items: center;
  align-items: center;
  border: 1px solid #fff;

  svg {
    color: #fff;
  }
}

.arrow-right {
  position: absolute;
  right: 10px;
  top: 45%;
  border: 1px solid #fff;
  z-index: 2;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-items: center;
  align-items: center;

  svg {
    color: #fff;
  }
}